@import '../../../../scss/theme-bootstrap';

.header-offers-banner-item {
  color: $color-white;
  overflow: hidden;
  text-align: center;
  width: auto;
  @include breakpoint($landscape-up) {
    overflow: unset;
  }
  &__offer-text {
    display: inline;
    p {
      display: inline;
      font-family: $font--label;
      margin: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.4;
    }
  }
  a {
    font-size: 12px;
    font-family: $font--label;
    color: $color-white;
  }
}
